<template>
  <div class="rounded overflow-hidden feature-zoom shadow-xl lg:mb-6 md:mb-4 sm:mb-4 flex flex-col">    
    <a :href="special.link" target="_blank" class="w-full no-underline">      
      <img
        :alt="special.name"
        :title="special.hero.copyright"
        :src="special.hero.url"
        class="w-full"
      >        
    <div class="flex-grow flex flex-col w-full mb-6">
      <div class="flex-grow px-6 py-4">
        <a :href="special.link" class="no-underline" target="_blank">
          <h3 class="text-overflow-ellipsis overflow-hidden whitespace-no-wrap ">{{ special.name }}</h3>
        </a>

        <span v-if="special.city.text" class="block text-gray-700">{{ special.city.text }}, {{ special.state_or_country.text }}</span>
        <div
          class="mt-2 facts-list three-item-list pt-4 text-gray-700 leading-normal"
          v-html="special.short_service_overview"
        ></div>
      </div>
      <div class="flex px-6 h-8 justify-between items-center">
        <div class="w-full">
          <h3 class="text-xs text-gray-700 mb-1">{{ translate("app.validity") }}</h3>
          <p class="text-xs text-gray-700">
            {{ special.valid_from }} - {{ special.valid_to }}
          </p>
        </div>

        <div class="w-full text-right">
          <h3 class="text-xs text-gray-700">{{ translate("app.price-per-person") }}</h3>
          <p class="font-sans text-xl font-bold">
            <span class="text-xs">{{ translate("app.starting_at") }}</span>
            {{ special.starting_at }}
          </p>
        </div>
      </div>
    </div>
  </a>
  </div>
</template>

<script>
export default {
  props: {
    special: Object
  }
}
</script>
