<template>
<a :href="service.link" class="no-underline rounded" target="_blank">
  <div class="grid lg:grid-cols-2 xl:grid-cols-2 shadow-lg feature-zoom h-full rounded">
    <div class="bg-cover bg-center h-56 sm:rounded-t md:rounded-t lg:rounded-l xl:rounded-l 2xl:rounded-l" :style="backgroundImage" :title="service.hero.copyright">
    </div>
    <div class="relative p-4">
        <a :href="service.link" class="no-underline" target="_blank">
          <h3 class="text-xl">{{ service.name }}</h3>
        </a>
        <span class="block text-gray-500 font-bold">{{ service.city.text }}</span>
        <div
          class="facts-list three-item-list mt-3 text-sm text-gray-700 leading-normal"
          v-html="service.summary"
        ></div>
    </div>
  </div>
</a>
</template>

<script>
export default {
  props: {
    service: Object
  },
  computed: {
    backgroundImage () {
      return {
        backgroundImage: "url(" + this.service.hero.url + ")"
      }
    }
  }
}
</script>
