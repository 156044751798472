<template>
  <div
    @click="selectDay"
    class="h-24 sm:h-18 border border-gray-200 text-right text-gray-700 relative"
    :class="classes"
  >
    <div class="absolute top-0 right-0 p-2 text-right sm:text-sm">
      <div
        :class="{ 'lg:flex lg:rounded-full lg:bg-secondary-400 lg:px-2 lg:py-1 lg:text-white': isToday }"
      >{{ dayString }}</div>
    </div>

    <!-- Loading State -->
    <div v-if="loading" class="flex justify-center items-center h-full text-gray-500-light sm:relative">
      <i class="far fa-circle-notch md:fa-2x lg:fa-2x fa-spin sm:absolute sm:bottom-0 sm:mb-2"></i>
    </div>

    <!-- Prices loaded -->
    <div v-else>
      <div v-if="hasAvailabilities" class="absolute bottom-0 right-0 p-2">
        <p class="font-bold sm:text-xs sm:font-bold">
          <span class="sm:block">{{ translate("app.starting_at") }}</span>
          <span class="sm:hidden">€</span>
          {{ Math.floor(lowestPrice.price_cents / 100) }},-
        </p>
      </div>
      <div v-else class="h-24 sm:h-18 bg-striped"></div>
    </div>
  </div>
</template>

<script>
import I18n from "../i18n.js.erb";
const Qs = require("qs");

export default {
  name: "calendar-day",

  props: {
    date: {
      required: true,
      type: Object
    },
    cursor: {
      required: true,
      type: Object
    },
    specials: {
      required: false,
      type: Array
    },
    housing: {
      required: true,
      type: Object
    },
    minStayDuration: {
      required: true,
      type: Number
    },
    cancelSource: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      loading: true,
      selected: false,
      availabilities: {},
      flatAvailabilities: [],
      today: moment()
    };
  },

  created() {
    this.loadPrices();
  },

  mounted() {},

  beforeUpdate() {},

  computed: {
    classes: function() {
      return {
        "bg-white": !this.selected,
        "hover:cursor-pointer hover:bg-gray-100": this.hasAvailabilities,
        "bg-green-100 hover:bg-green-200":
          this.hasSpecial && this.hasAvailabilities && !this.selected,
        "bg-blue-200": this.selected
      };
    },

    lowestPrice: function() {
      return _.minBy(this.flatAvailabilities, function(av) {
        return av.price_cents;
      });
    },

    isInCurrentMonth: function() {
      return this.cursor.toDate().getMonth() == this.date.month();
    },

    isToday: function() {
      return this.date.format("YYYY-MM-DD") == this.today.format("YYYY-MM-DD");
    },

    firstDayOfMonth: function() {
      return this.date.date() == 1;
    },

    hasSpecial: function() {
      return !_.isEmpty(this.specials);
    },

    hasAvailabilities: function() {
      return !_.isEmpty(this.flatAvailabilities);
    },

    dayString: function() {
      if (this.firstDayOfMonth) {
        return I18n.strftime(this.date.toDate(), "%d.%B");
      } else {
        return I18n.strftime(this.date.toDate(), "%d");
      }
    }
  },

  methods: {
    selectDay: function() {
      this.$emit("selected", this);
    },

    loadPrices: function() {
      if (this.hasSpecial) {
        this.loadSpecialPrices();
      } else {
        this.loadNormalPrices();
      }
    },

    loadSpecialPrices: function() {
      let self = this;
      let locale = I18n.locale;
      let url = "/" + locale + "/api/v1/hotels/availabilities";

      _.each(self.specials, function(special) {
        let durationKey = "" + special.stay_duration;

        if (!self.availabilities.hasOwnProperty(durationKey)) {
          let temp = self.availabilities;
          temp[durationKey] = [];
          self.availabilities = temp;
        }

        axios
          .get(url, {
            params: {
              id: self.housing.code,
              hotel_search: {
                arrival: special.arrival,
                departure: special.departure
              }
            },
            cancelToken: self.cancelSource.token,
            paramsSerializer: function(params) {
              return Qs.stringify(params, { arrayFormat: "brackets" });
            }
          })
          .then(function(response) {
            let data = response.data;

            let temp = self.availabilities;
            let avData = temp[durationKey];
            avData == avData.push(data);
            avData = _.flatten(avData);
            temp[durationKey] = avData;

            self.availabilities = temp;
            self.flatAvailabilities = self.flatAvailabilities.concat(
              _.flatten(data)
            );
            self.loading = false;
          })
          .catch(function(error) {
            if (axios.isCancel(error)) {
              // console.log("Request cancelled");
            }

            self.loading = false;
          });
      });
    },

    loadNormalPrices: function() {
      let self = this;
      let locale = I18n.locale;
      let url = "/" + locale + "/api/v1/hotels/availabilities";

      let currentDate = self.date.clone();
      let arrival = currentDate.format("YYYY-MM-DD");
      let departure = currentDate
        .add(self.minStayDuration, "day")
        .format("YYYY-MM-DD");

      axios
        .get(url, {
          params: {
            id: self.housing.code,
            hotel_search: {
              arrival: arrival,
              departure: departure
            }
          },
          cancelToken: self.cancelSource.token,
          paramsSerializer: function(params) {
            return Qs.stringify(params, { arrayFormat: "brackets" });
          }
        })
        .then(function(response) {
          let data = response.data;

          if(!data.length) {
            self.loading = false;
            return;
          }

          let durationKey = self.minStayDuration;
  
          let temp = {};
          temp[durationKey] = [];
          let avData = temp[durationKey];
          avData == avData.push(data);
          avData = _.flatten(avData);
          temp[durationKey] = avData;

          self.availabilities = temp;
          self.flatAvailabilities = self.flatAvailabilities.concat(
            _.flatten(data)
          );
          self.loading = false;
        })
        .catch(function(error) {
          if (axios.isCancel(error)) {
            // console.log("Request cancelled");
          }
          self.loading = false;
        });
    }
  }
};
</script>

<style>
.bg-striped {
  background-image: linear-gradient(
    135deg,
    #f1f5f8 25%,
    #ffffff 25%,
    #ffffff 50%,
    #f1f5f8 50%,
    #f1f5f8 75%,
    #ffffff 75%,
    #ffffff 100%
  );
  background-size: 42.43px 42.43px;
}
</style>



