import Vue from "vue/dist/vue.esm"
import Vuex from "vuex"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    room_summary: "",
    selected_rooms: []
  },

  mutations: {
    set_room_summary (state, summary) {
      state.room_summary = summary
    },

    set_selected_rooms (state, rooms) {
      state.selected_rooms = rooms
    }
  }

})
