<template>
  <div>
    <!-- <transition name="fade"> -->
      <div v-if="active" class="my-4">
        <slot name="header"></slot>
        <slot name="body"></slot>
        <slot name="footer"></slot>
      </div>
    <!-- </transition> -->
  </div>
</template>

<script>
export default {
  name: "tab",
  data() {
    return {
      active: false
    };
  },
  props: {
    name: {
      required: true,
      type: String
    },
    default: {
      required: false,
      type: Boolean
    }
  },
  mounted() {
    this.$parent.addTab(this);
  },
  beforeDestroy() {
    this.$parent.removeTab(this);
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
