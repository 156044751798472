<script>
import store from '../store'

export default {

  props: ["exclusive", "preventOverlapping", "allotmentCheck"],

  data() {
    return {
      availabilities: gon.availabilities
    }
  },

  watch: {
    availabilities: {
      handler: function(_new, _old) {
        this.save_data()
      },
      deep: true
    }
  },

  computed: {
    selected_rooms: function () {
      return _.filter(this.availabilities, o => o.number_of_rooms_selected > 0)
    },
  },

  mounted() {
    this.availabilities[0].number_of_rooms_selected = 1
    this.selectedRoom(this.availabilities[0])
  },

  methods: {
    save_data() {
      store.commit("set_selected_rooms", this.selected_rooms)
    },

    selectedRoom (selected_room)  {
      if(this.exclusive == "true") {
        this.availabilities = _.each(this.availabilities, o => o.number_of_rooms_selected = 0)
        selected_room.number_of_rooms_selected = 1
      }

      if(this.preventOverlapping == "true") {
        let arrival = moment(selected_room.arrival_date)
        let departure = moment(selected_room.departure_date)
        let conflicts_with_selected_price = false

        _.each(this.selected_rooms, function(sr) {
          if(sr.uid == selected_room.uid) {
            return
          }

          let sr_arrival = moment(sr.arrival_date)
          let sr_departure = moment(sr.departure_date)

          // Check of Date ranges overlap
          if(arrival < sr_departure && departure > sr_arrival) {
            conflicts_with_selected_price = true
          }
        })

       if(conflicts_with_selected_price) {
         this.availabilities = _.each(this.availabilities, o => o.number_of_rooms_selected = 0)
         selected_room.number_of_rooms_selected = 1
       }
      }

      if(this.allotmentCheck == "true") {
        _.each(this.availabilities, function(current_room) {
          if(current_room.uid == selected_room.uid) {
            return
          }

          // Reduce the selectable number of rooms for availabilities with the same allotment service code
          // Make sure no availability is selected too often
          if(current_room.allotment_service_code == selected_room.allotment_service_code) {
            current_room.number_of_rooms_available_for_selection = current_room.number_of_rooms_available - selected_room.number_of_rooms_selected
            current_room.number_of_rooms_selected = Math.min(current_room.number_of_rooms_selected, selected_room.number_of_rooms_available_for_selection)
          }
        })
      }
    }
  }
};

</script>
