<template>
  <div id="calendar-wrapper" class="mb-4">
    <div id="calendar-controls" class="w-full flex justify-between content-center">
      <div>
        <span class="text-2xl font-bold">{{ currentMonthName }}</span>
        <span class="text-2xl">{{ currentYear }}</span>
      </div>
      <div>
        <button v-if="enableNextMonthButton" @click="prevMonth" type="button" class="p-2 border">
          <i class="far fa-chevron-left"></i>
        </button>
        <button type="button" class="p-2 border" @click="jumpToToday">{{ translate("app.today") }}</button>
        <button v-if="enableNextMonthButton" @click="nextMonth" type="button" class="p-2 border">
          <i class="far fa-chevron-right"></i>
        </button>
      </div>
    </div>
    <div id="calendar-header" class="w-full">
      <div
        v-for="(name, index) in dayLabels"
        :key="index"
        class="text-right p-2 font-bold"
      >{{ name }}</div>
    </div>
    <div id="calendar" class="w-full">
      <calendar-day
        v-for="day in daysInCursorMonth"
        @selected="selectDay"
        :key="day.format('YYYY-MM-DD')"
        :date="day"
        :specials="specialsForDay(day)"
        :cursor="cursor"
        :housing="housing"
        :cancel-source="cancelSource"
        :min-stay-duration="minStayDuration"
      />
    </div>
    <div id="legend" class="w-full flex flex-wrap justify-between text-gray-700 mt-2 sm:font-sm">
      <div class="flex justify-between">
        <div>
          <span class="inline-block h-5 w-5 bg-green-100 border mr-1"></span>{{ translate("app.special-price") }}
        </div>
        <div class="mx-2">
          <span class="inline-block h-5 w-5 bg-white border mr-1"></span>{{ translate("app.normal-price") }}
        </div>
        <div>
          <span class="inline-block h-5 w-5 legend-striped border mr-1"></span>{{ translate("app.on-request") }}
        </div>
      </div>
      <div>
        {{ translate("app.total-per-room-and-stay") }}
      </div>
    </div>

    <div id="info" class="my-4">
      <div v-if="this.selectedDay">
        <tabs class="my-6">
          <div v-for="(avGroup, key) in this.selectedDay.availabilities" :key="key">
            <tab
              :name="key + ' Nächte'"
              :default="key == minStayDuration"
              v-if="avGroup.length > 0"
            >
              <div slot="body">
                <div v-for="(av, index) in avGroup" :key="index">
                  <div class="mb-2 pb-2 border-b border-solid border-gray-200">
                    <h3>{{ av.room_type}}</h3>
                    <div class="flex flex-wrap">
                      <div class="lg:w-1/5 md:w-1/4 sm:w-1/2 mt-2">
                        <ul class="fa-ul text-gray-700">
                          <li v-for="(facility, index) in av.facilities" :key="index">
                            <span class="fa-li">
                              <i class="far fa-check-circle"></i>
                            </span>
                            {{ facility }}
                          </li>
                        </ul>
                      </div>
                      <div class="lg:w-1/4 md:w-1/5 sm:w-1/2 lg:text-center md:text-center text-gray-700 mt-2"
                      >
                        <p>
                          <span class="block">{{ av.display_dates }}</span>
                          <span class="block text-xs">{{ av.meal_description }}</span>
                        </p>
                      </div>
                      <div class="lg:w-1/5 md:w-1/4 sm:w-1/2 lg:text-center md:text-center mt-2 sm:pl-4"
                      >
                        <span
                          class="inline block text-2xl font-sans font-bold"
                        >€ {{ av.display_price }}</span>
                        <div class="text-gray-700 text-xs">{{ translate("app.total-price") }}</div>
                      </div>
                      <div class="sm:w-1/2 md:hidden lg:hidden xl:hidden"></div>
                      <div class="lg:w-1/3 md:w-1/4 sm:w-full mt-2">
                        <a
                          class="font-bold p-3 border no-underline leading-normal inline-block hover:bg-secondary-500 hover:text-white"
                          :href="selectedDetailUrl(av)"
                          target="_blank"
                        >{{ translate("app.view-hotel") }}</a>
                        <form
                          action="/de/hotel-booking/occupancy"
                          method="post"
                          class="inline-block"
                        >
                          <input type="hidden" name="authenticity_token" :value="csrf">
                          <input type="hidden" name="booking[arrival]" :value="av.arrival_date">
                          <input type="hidden" name="booking[departure]" :value="av.departure_date">
                          <input type="hidden" name="booking[amount_cents]" :value="av.price_cents">
                          <input
                            type="hidden"
                            name="booking[housing_code]"
                            :value="av.housing_code"
                          >
                          <input type="hidden" name="booking[locale]" value="de">
                          <input type="hidden" name="booking[rooms][][number_of_rooms]" value="1">
                          <input
                            type="hidden"
                            name="booking[rooms][][housing_code]"
                            :value="av.housing_code"
                          >
                          <input
                            type="hidden"
                            name="booking[rooms][][room_code]"
                            :value="av.room_code"
                          >
                          <input
                            type="hidden"
                            name="booking[rooms][][room_type]"
                            :value="av.room_type"
                          >
                          <input
                            type="hidden"
                            name="booking[rooms][][meal_code]"
                            :value="av.meal_code"
                          >
                          <input
                            type="hidden"
                            name="booking[rooms][][meal_description]"
                            :value="av.meal_description"
                          >
                          <input
                            type="hidden"
                            name="booking[rooms][][number_of_adults]"
                            :value="av.searched_number_of_adults"
                          >
                          <input
                            type="hidden"
                            name="booking[rooms][][number_of_children]"
                            :value="av.searched_number_of_children"
                          >
                          <input
                            type="hidden"
                            name="booking[rooms][][arrival]"
                            :value="av.arrival_date"
                          >
                          <input
                            type="hidden"
                            name="booking[rooms][][departure]"
                            :value="av.departure_date"
                          >
                          <input
                            v-for="(note, index) in av.notes"
                            :key="index"
                            type="hidden"
                            name="booking[notes][]"
                            :value="note"
                          >
                          <input
                            v-if="av.notes.length == 0"
                            type="hidden"
                            name="booking[notes][]"
                            value=""
                          >
                          <input
                            v-if="special"
                            type="hidden"
                            name="booking[special_offer_id]"
                            :value="special.id"
                          >
                          <button
                            type="submit"
                            class="font-bold p-3 border no-underline bg-orange-500 leading-normal text-white hover:bg-orange-500-dark"
                          >{{ translate("booking.book-button") }}</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </tab>
          </div>
        </tabs>
      </div>
      <h2 class="mb-2 mt-4">{{ translate("app.travel-period-not-found") }}</h2>
        <form action="/de/hotels/search" accept-charset="UTF-8" method="post" class="simple_form hotel-search-form text-gray-700">
          <input type="hidden" name="authenticity_token" :value="csrf">
          <input type="hidden" name="hotel_search[rooms][][adults]" value="2">
          <input type="hidden" name="hotel_search[rooms][][children]" value="0">

          <input
            type="hidden"
            name="hotel_search[autocomplete_dataset]"
            id="hotel_search_autocomplete_dataset"
            class="hidden"
            value="hotels"
          >
          <input
            type="hidden"
            name="hotel_search[autocomplete_slug]"
            id="hotel_search_autocomplete_slug"
            class="hidden"
            :value="this.housing.slug"
          >

          <div class="lg:flex lg:justify-start flex-wrap">
            <div class="lg:flex-1 lg:flex md:flex md:flex-wrap md:mb-2">
              <div class="lg:flex-1 lg:pr-2 md:w-1/2">
                <hotel-date-picker inline-template="inline-template">
                  <div class="datepicker-trigger">
                    <div class="relative">
                      <input
                        readonly="readonly"
                        v-model="display_string"
                        onfocus="this.blur()"
                        id="datepicker-trigger2"
                        class="pl-10 text-gray-700 hover:cursor-pointer lg:w-full md:w-full sm:w-full sm:mb-2"
                        placeholder="Reisedatum"
                      >
                      <i
                        class="far fa-calendar-alt absolute left-0 top-0 mt-3 ml-3"
                        data-fa-transform="grow-1 down-2"
                      ></i>
                    </div>
                    <input
                      v-model="iso_arrival"
                      type="hidden"
                      name="hotel_search[arrival]"
                      id="hotel_search_arrival"
                      class="hidden"
                    >
                    <input
                      v-model="iso_departure"
                      type="hidden"
                      name="hotel_search[departure]"
                      id="hotel_search_departure"
                      class="hidden"
                    >
                    <airbnb-style-datepicker
                      :trigger-element-id="'datepicker-trigger2'"
                      :fullscreen-mobile="true"
                      :offset-y="8"
                      :date-one="arrival"
                      :date-two="departure"
                      @date-one-selected="arrival_selected"
                      @date-two-selected="departure_selected"
                    />
                  </div>
                </hotel-date-picker>
              </div>
            </div>
            <div class="lg:flex-1 flex flex-wrap lg:flex-no-wrap">
              <div class="lg:flex-1 md:w-1/2 sm:w-full">
                <button class="flex-1 w-full block btn btn-secondary">{{ translate("app.find-prices") }}</button>
              </div>
            </div>
          </div>
        </form>
    </div>
  </div>
</template>

<script>
import Month from "calendar-months";
import I18n from "../i18n.js.erb"
const qs = require("qs");

export default {
  name: "calendar2",

  props: {
    special: {
      required: false,
      type: Object
    },
    startDate: {
      required: true,
      type: String
    },
    endDate: {
      required: true,
      type: String
    },
    specials: {
      required: true,
      type: Object
    },
    housing: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      dayLabels: null,
      cursor: null,
      selectedDay: null,
      cancelSource: null,
      CancelToken: null,
      today: dayjs().startOf("day"),
      csrf: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content")
    };
  },

  created() {
    let daysShort = I18n.t("date.abbr_day_names");
    daysShort.push(daysShort.shift());
    this.dayLabels = daysShort;

    this.cursor = _.max([this.today, dayjs(this.startDate)]);

    this.CancelToken = axios.CancelToken;
    this.cancelSource = this.CancelToken.source();
  },

  computed: {
    daysInCursorMonth: function() {
      let self = this;
      let month = Month.create(this.cursor.toDate());

      // 1 means weeks start on monday
      let days = month.calendarDays(1);
      // Only use 5 (35 days) weeks instead of 6 (42 days)
      days = days.slice(0, 35);
      return days;
    },

    minStayDuration: function() {
      return _.min(
        _.map(_.flatten(_.values(this.specials)), function(v) {
          return v.stay_duration;
        })
      );
    },

    currentYear: function() {
      return this.cursor.format("YYYY");
    },

    currentMonthName: function() {
      return I18n.strftime(this.cursor.toDate(), "%B")
    },

    enablePrevMonthButton: function() {
      return true;
    },

    enableNextMonthButton: function() {
      return true;
    }
  },
  methods: {
    specialsForDay: function(day) {
      let date = day.format("YYYY-MM-DD");

      if (this.specials.hasOwnProperty(date)) {
        return this.specials[date];
      } else {
        return [];
      }
    },

    selectedDetailUrl: function(av) {
      let params = {
        hotel_search: {
          arrival: av.arrival_date,
          departure: av.departure_date,
          length_of_stay: av.stay_duration,
          rooms: [
            {
              adults: "2",
              children: "0"
            }
          ]
        }
      };
      params = qs.stringify(params, { arrayFormat: "brackets" });
      return this.housing.detail_url + "?" + params;
    },

    cancelAvailabilityRequests: function() {
      // TODO cancel only this months requests
      // this.cancelSource.cancel();
      // this.cancelSource = this.CancelToken.source();
    },

    jumpToToday: function() {
      this.cancelAvailabilityRequests();
      this.cursor = moment();
    },

    nextMonth: function() {
      this.cancelAvailabilityRequests();
      this.cursor = this.cursor.add(1, "month");
    },

    prevMonth: function() {
      this.cancelAvailabilityRequests();
      this.cursor = this.cursor.subtract(1, "month");
    },

    selectDay: function(day) {
      if(this.selectedDay) {
        this.selectedDay.selected = false;
      }

      this.selectedDay = day;
      this.selectedDay.selected = true;

      // this.selectedDate = day.date;
      // this.selectedAvailabilities = day.availabilities;

      $("html, body").animate(
        {
          scrollTop: $("#info").offset().top
        },
        800
      );
    }
  }
};
</script>

<style lang="sass">
  /* autoprefixer grid: autoplace */

  #calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }

  #calendar-header {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
  }

  .legend-striped {
    background-color: #fff;
    background-image: -webkit-linear-gradient(135deg, #fff 50%, #f1f5f8 50%);
  }
</style>


