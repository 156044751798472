<template>
  <div>
    <div class="container mx-auto pakete">
      <div v-if="!hideFilters"
        class="search-container lg:sticky mt-6 lg:top-0 md:sticky md:top-0 sm:mt-0 sm:px-4 sm:pin-none rounded"
      >
        <div class="container mx-auto md:px-6 md:border-gray-200">
          <form class="package-search-form text-gray-700">
            <div class="lg:flex lg:justify-between flex-wrap">
              <div class="lg:flex-1 lg:flex md:flex md:flex-wrap md:mb-2">
                <div class="lg:flex-1 lg:pr-2 md:w-1/2 sm:mb-2">
                  <div class="relative lg:pr-2">
                    <select2
                      :options="localityOptions"
                      :placeholder="translate('search.select-any-locality')"
                      v-model="selectedLocality"
                      class="text-gray-700 w-full block appearance-none bg-white border border-gray-200 hover:border-gray-500 pl-10 px-4 py-2 pr-8 sm:mb-2"
                    ></select2>
                    <i
                      class="far fa-tasks absolute left-0 top-0 mt-3 ml-3"
                      data-fa-transform="grow-1 down-2"
                    ></i>
                  </div>
                </div>
                <div class="lg:flex-1 lg:pr-2 md:w-1/2 sm:mb-2">
                  <div class="relative lg:pr-2">
                    <select2
                      :options="categoryOptions"
                      :placeholder="translate('search.select-category')"
                      v-model="selectedCategory"
                      class="text-gray-700 w-full block appearance-none bg-white border border-gray-200 hover:border-gray-500 pl-10 px-4 py-2 pr-8 sm:mb-2"
                    ></select2>
                    <i
                      class="far fa-tasks absolute left-0 top-0 mt-3 ml-3"
                      data-fa-transform="grow-1 down-2"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="container mx-auto mt-6 tablet-landscape-fix">
        <paginate name="filteredPackages" :list="filteredPackages" :per="20" tag="div">
          <div
            class="flex-grid-full md:flex-grid-1/2 lg:flex-grid-1/3 xl:flex-grid-1/3 flex justify-between flex-wrap"
          >
            <package
              v-for="offer in paginated('filteredPackages')"
              :package="offer"
              :key="offer.id"
            ></package>
          </div>
        </paginate>

        <paginate-links v-if="!hidePagination" for="filteredPackages" :limit="20" :show-step-links="true"></paginate-links>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "package-list",

  props: {
    packages: Array,
    hideFilters: Boolean,
    hidePagination: Boolean,
    preSelectedLocality: String,
    preSelectedCategory: String
  },

  data() {
    return {
      packagesForLocalityFilter: this.packages,
      packagesForCategoryFilter: this.packages,
      selectedLocality: this.preSelectedLocality,
      selectedCategory: this.preSelectedCategory,
      paginate: ["filteredPackages"]
    };
  },

  computed: {
    filteredPackages: function() {
      let filtered = this.packages;
      let selectedLocality = parseInt(this.selectedLocality);
      let selectedCategory = parseInt(this.selectedCategory);

      if (selectedLocality) {
        filtered = _.filter(filtered, function(offer) {
          return offer.state_or_country.id == selectedLocality;
        });
      }

      if (selectedCategory) {
        filtered = _.filter(filtered, function(offer) {
          let packageCategories = _.map(offer.categories, function(category) {
            return category.id;
          });

          return _.includes(packageCategories, selectedCategory);
        });
      }

      return filtered;
    },

    localityOptions: function() {
      let collection = this.packagesForLocalityFilter;

      let allLocalities = _.map(collection, function(offer) {
        return offer.state_or_country;
      });

      let uniqueLocalities = _.uniqBy(allLocalities, function(locality) {
        return locality.id;
      });
      return uniqueLocalities;
    },

    categoryOptions: function() {
      let collection = this.packagesForCategoryFilter;

      let allCategories = _.flatMap(collection, function(offer) {
        return offer.categories;
      });

      let uniqueCategories = _.uniqBy(allCategories, function(category) {
        return category.id;
      });

      return uniqueCategories;
    }
  },

  beforeUpdate() {
    $(".lg\\:flex-grid-1\\/3 > div:empty").remove();
  },

  updated() {
    this.$nextTick(function() {
      this.fixGrid();
    });
  },

  watch: {
    selectedLocality: {
      handler: function(newVal, _oldVal) {
        if (newVal) {
          // Locality Selected
          this.packagesForCategoryFilter = this.filteredPackages;
        } else {
          // Locality deselected
          this.packagesForCategoryFilter = this.packages;
        }
      },
      immediate: true
    },

    selectedCategory: {
      handler: function(newVal, _oldVal) {
        if (newVal) {
          this.packagesForLocalityFilter = this.filteredPackages;
        } else {
          this.packagesForLocalityFilter = this.packages;
        }
      },
      immediate: true
    }
  },

  methods: {
    fixGrid: function() {
      let $grid = $(".lg\\:flex-grid-1\\/3");

      if (($grid.children().length + 1) % 3 == 0) {
        $grid.append("<div></div>");
      }
    }
  }
};
</script>
