<template>
  <div>
    <div class="container mx-auto sonderangebote">     
      <div v-if="!hideFilters"
        class="search-container lg:sticky lg:top-0 md:sticky md:top-0 sm:mt-0 sm:px-4 sm:pin-none">
        <div class="container mx-auto md:px-6 md:border-gray-200">
          <form class="package-search-form text-gray-700">
            <div class="lg:flex lg:justify-between flex-wrap">
              <div class="lg:flex-1 lg:flex md:flex md:flex-wrap md:mb-2">
                <div class="lg:flex-1 lg:pr-2 md:w-1/2 sm:mb-2">
                  <div class="relative lg:pr-2">
                    <select2
                      :options="localityOptions"
                      :placeholder="translate('search.select-any-locality')"
                      v-model="selectedLocality"
                      class="text-gray-700 w-full block appearance-none bg-white border border-gray-200 hover:border-gray-500 pl-10 px-4 py-2 pr-8 sm:mb-2"
                    ></select2>
                    <i
                      class="far fa-tasks absolute left-0 top-0 mt-3 ml-3"
                      data-fa-transform="grow-1 down-2"
                    ></i>
                  </div>
                </div>
                <div class="lg:flex-1 md:w-1/2 sm:mb-2">
                  <div class="relative">
                    <select2
                      :options="categoryOptions"
                      :placeholder="translate('search.select-category')"
                      v-model="selectedCategory"
                      class="text-gray-700 w-full block appearance-none bg-white border border-gray-200 hover:border-gray-500 pl-10 px-4 py-2 pr-8 sm:mb-2"
                    ></select2>
                    <i
                      class="far fa-tasks absolute left-0 top-0 mt-3 ml-3"
                      data-fa-transform="grow-1 down-2"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="container mx-auto mt-6">
        <paginate name="filteredSpecials" :list="filteredSpecials" :per="perPage" tag="div">
          <div
            class="flex-grid-full md:flex-grid-1/2 lg:flex-grid-1/3 xl:flex-grid-1/3 flex justify-between flex-wrap"
          >
            <special
              v-for="special in paginated('filteredSpecials')"
              :special="special"
              :key="special.id"
            ></special>
          </div>
        </paginate>

        <paginate-links v-if="!hidePagination" for="filteredSpecials" :limit="9" :show-step-links="true"></paginate-links>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "special-list",

  props: {
    specials: Array,
    hideFilters: Boolean,
    hidePagination: Boolean,
    preSelectedLocality: String,
    preSelectedCategory: String,
    perPage: Number
  },

  data() {
    return {
      specialsForLocalityFilter: this.specials,
      specialsForCategoryFilter: this.specials,
      selectedLocality: this.preSelectedLocality,
      selectedCategory: this.preSelectedCategory,
      paginate: ["filteredSpecials"]
    };
  },

  computed: {
    filteredSpecials: function() {
      let filtered = this.specials;
      let selectedLocality = parseInt(this.selectedLocality);
      let selectedCategory = parseInt(this.selectedCategory);

      if (selectedLocality) {
        filtered = _.filter(filtered, function(special) {
          return special.state_or_country.id == selectedLocality;
        });
      }

      if (selectedCategory) {
        filtered = _.filter(filtered, function(special) {
          let specialCategories = _.map(special.categories, function(category) {
            return category.id;
          });

          return _.includes(specialCategories, selectedCategory);
        });
      }

      return filtered;
    },

    localityOptions: function() {
      let collection = this.specialsForLocalityFilter;

      let allLocalities = _.map(collection, function(special) {
        return special.state_or_country;
      });

      let uniqueLocalities = _.uniqBy(allLocalities, function(locality) {
        return locality.id;
      });
      return uniqueLocalities;
    },

    categoryOptions: function() {
      let collection = this.specialsForCategoryFilter;

      let allCategories = _.flatMap(collection, function(special) {
        return special.categories;
      });

      let uniqueCategories = _.uniqBy(allCategories, function(category) {
        return category.id;
      });

      return uniqueCategories;
    }
  },

  beforeUpdate() {
    $(".lg\\:flex-grid-1\\/3 > div:empty").remove();
  },

  updated() {
    this.$nextTick(function() {
      this.fixGrid();
    });
  },

  watch: {
    selectedLocality: {
      handler: function(newVal, _oldVal) {
        if (newVal) {
          // Locality Selected
          this.specialsForCategoryFilter = this.filteredSpecials;
        } else {
          // Locality deselected
          this.specialsForCategoryFilter = this.specials;
        }
      },
      immediate: true
    },

    selectedCategory: {
      handler: function(newVal, _oldVal) {
        if (newVal) {
          this.specialsForLocalityFilter = this.filteredSpecials;
        } else {
          this.specialsForLocalityFilter = this.specials;
        }
      },
      immediate: true
    }
  },

  methods: {
    fixGrid: function() {
      let $grid = $(".lg\\:flex-grid-1\\/3");

      if (($grid.children().length + 1) % 3 == 0) {
        $grid.append("<div></div>");
      }
    }
  }
};
</script>
