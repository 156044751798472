<script>
export default {
  data() {
    return {
      arrival: this.default_arrival(),
      departure: this.default_departure(),
      special: this.default_special(),
      open: false
    };
  },

  computed: {
    iso_arrival: function() {
      if (this.special) {
        return null;
      }

      if (!this.arrival) {
        return null
      }

      return moment(this.arrival).format("YYYY-MM-DD");
    },

    iso_departure: function() {
      if (this.special) {
        return null;
      }

      if (!this.departure) {
        return null
      }

      return moment(this.departure).format("YYYY-MM-DD");
    },

    display_string: function() {
      if (this.special) {
        if (
          _.includes(
            ["kurz-mal-weg", "schnappchen", "topangebot"],
            this.special
          )
        ) {
          return I18n.t("hut-special." + this.special);
        } else {
          return humanize(this.special);
        }
      }

      let arrival = moment(this.arrival)
      let departure = moment(this.departure)
      let arrival_string,
        departure_string,
        display_string = null

      if (arrival.isValid()) {
        arrival_string = I18n.l("date.formats.default", arrival.toDate())
      }

      if (departure.isValid()) {
        departure_string = I18n.l("date.formats.default", departure.toDate())
      }

      // Only Arrival selected
      if (arrival_string && !departure_string) {
        display_string = `${arrival_string}  -  ${I18n.t("app.departure")}`
      }

      // Arrival and departure selected
      if (arrival_string && departure_string) {
        display_string = `${arrival_string}  -  ${departure_string}`
      }

      // Nothing selected
      return display_string
    }
  },

  methods: {
    arrival_selected(val) {
      this.arrival = val;

      if (this.open == true) {
        this.special = null;
      }
    },

    departure_selected(val) {
      this.departure = val;

      if (this.open == true) {
        this.special = null;
      }
    },

    apply_dates() {
      this.special = null;
    },

    opened() {
      this.open = true;
    },

    closed() {
      this.open = false;
    },

    clear_date() {
      this.arrival = null
      this.departure = null
      this.special = null
    },

    default_arrival() {
      const $arrival = $("#hut_search_arrival");
      if ($arrival.attr("data-initial")) {
        return moment($arrival.data("initial")).toDate();
      } else {
        return null
      }
    },

    default_departure() {
      const $departure = $("#hut_search_departure");
      if ($departure.attr("data-initial")) {
        return moment($departure.data("initial")).toDate();
      } else {
        return null
      }
    },

    default_special() {
      const $special = $("#hut_search_special");
      if ($special.attr("data-initial")) {
        return $special.data("initial");
      } else {
        return null;
      }
    }
  }
};
</script>
