<template>
  <select>
    <slot></slot>
  </select>
</template>

<script>
export default {

  props: {
    options: Array,
    value: String,
    placeholder: String
  },

  mounted: function() {
    var vm = this;
    $(this.$el)
      // init select2
      .select2({
        data: this.options,
        allowClear: true,
        placeholder: this.placeholder
      })
      .val(this.value)
      .trigger("change")
      // emit event on change.
      .on("change", function() {
        vm.$emit("input", this.value);
      });
  },

  watch: {
    value: function(value) {
      // update value
      $(this.$el)
        .val(value)
        .trigger("change");
    },
    options: function(options) {
      // update options
      $(this.$el)
        .empty()
        .select2({
          data: options,
          allowClear: true,
          placeholder: this.placeholder
        }).val(this.value).trigger("change")
    }
  },

  destroyed: function() {
    $(this.$el)
      .off()
      .select2("destroy");
  }
};
</script>
