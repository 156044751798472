<template>
  <div>
    <ul class="flex border-b">
      <li
        v-for="tab in this.tabs"
        :key="tab.name"
        @click="switchTab(tab)"
        :class="classes(tab)"
      >{{ tab.name }}</li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "tabs",
  data() {
    return {
      tabs: []
    };
  },
  mounted() {
    this.switchTab(this.defaultTab);
  },
  beforeUpdate() {
    // Make sure a tab is selected after the number of tabs changes
    // e.g after removing all tabs and then adding others
    if (!this.activeTab && this.tabs.length > 0) {
      this.switchTab(this.defaultTab);
    }
  },
  methods: {
    addTab: function(tab) {
      this.tabs.push(tab);
    },
    removeTab: function(tab) {
      this.tabs = _.remove(this.tabs, function(t) {
        return t.name == tab.name;
      });
    },
    switchTab: function(tab) {
      let requestedTab = _.find(this.tabs, function(t) {
        return t.name == tab.name;
      });

      if (this.activeTab) {
        this.activeTab.active = false;
      }

      requestedTab.active = true;
    },
    classes: function(tab) {
      return {
        "mr-1 inline-block py-2 px-4 hover:cursor-pointer font-semibold md:text-lg lg:text-lg font": true,
        "-mb-px border-l border-t border-r rounded-t font-bold bg-secondary-500 text-white": tab.active,
        "text-gray-700 hover:text-secondary-500 bg-white": !tab.active
      };
    }
  },
  computed: {
    activeTab: function() {
      return _.find(this.tabs, function(t) {
        return t.active == true;
      });
    },
    defaultTab: function() {
      // Check if a default tab is provided, otherwise choose
      // the first added tab as the default tab
      let providedDefaultTab = _.find(this.tabs, function(t) {
        return t.default == true;
      });

      if (!providedDefaultTab) {
        return this.tabs[0];
      } else {
        return providedDefaultTab;
      }
    }
  }
};
</script>