<template>
  <div
    v-if="visible"
    @click="close"
    class="modal-mask p-8 z-50 fixed top-0 left-0 flex mx-auto min-h-screen w-screen"
  >
    <div class="rounded bg-white shadow-xl m-auto w-11/12 relative z-40 max-h-screen overflow-auto" @click.stop>
      <span
        class="block text-xl font-bold absolute top-0 right-0 mt-2 mr-4 hover:cursor-pointer"
        @click="close"
      >x</span>
      <div class="p-5 mt-8">
        <slot name="header"></slot>
        <slot name="body"></slot>
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false
    };
  },

  mounted: function() {
    document.addEventListener("keydown", e => {
      if (this.visible && e.keyCode == 27) {
        this.close();
      }
    });
  },

  methods: {
    open: function() {
      $(window).scrollTop(0);
      $("body").addClass("overflow-hidden");
      $(".search-container").removeClass("md:sticky lg:sticky xl:sticky");
      this.visible = true;
    },
    close: function() {
      $("body").removeClass("overflow-hidden");
      $(".search-container").addClass("md:sticky lg:sticky xl:sticky");
      this.visible = false;
    }
  }
};
</script>