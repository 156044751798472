<template> 
  <div class="w-full lg:w-1/3 xl:w-1/3 2xl:w-1/3 rounded overflow-hidden feature-zoom shadow-xl relative flex flex-col mb-6">
    <a :href="package.link" class="contents no-underline" target="_blank">
      <img 
        :alt="package.name"
        :title="package.hero.copyright"
        :src="package.hero.url"
        class="w-full"
      >    
      <div class="px-6 py-4 h-full">
        <div class="mb-2">
          <h3 class="text-overflow-ellipsis overflow-hidden whitespace-no-wrap">{{ package.name }}</h3>
          <span v-if="package.city.text" class="block text-gray-700">{{ package.city.text }}, {{ package.state_or_country.text }}</span>  
        </div>
        <div
          class="mt-2 facts-list three-item-list pt-4 text-gray-700 leading-normal"
          v-html="package.summary"
        ></div>
      </div>
      <div class="flex px-6 h-12 justify-between items-center mb-4">
        <div class="w-full">
          
            <h3 class="text-xs text-gray-700 mb-1">{{ translate("app.validity") }}</h3>
            <p class="text-xs text-gray-700">
              {{ package.valid_from }} - {{ package.valid_to }}
            </p>
        </div>
        <div class="w-full text-right">
          <h3 class="text-xs text-gray-700">{{ translate("app.price-per-person") }}</h3>
          <p class="font-sans text-xl font-bold">
            <span class="text-xs">{{ translate("app.starting_at") }}</span>
            {{ package.starting_at }}
          </p>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    package: Object
  }
}
</script>
