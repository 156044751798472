<script>
export default {
  data() {
    return {
      arrival: this.default_arrival(),
      departure: this.default_departure()
    }
  },
  computed: {
    iso_arrival: function() {
      if (!this.arrival) {
        return null
      }

      return moment(this.arrival).format("YYYY-MM-DD")
    },

    iso_departure: function() {
      if (!this.departure) {
        return null
      }

      return moment(this.departure).format("YYYY-MM-DD")
    },

    display_string: function() {
      let arrival = moment(this.arrival)
      let departure = moment(this.departure)
      let arrival_string,
        departure_string,
        display_string = null

      if (arrival.isValid()) {
        arrival_string = I18n.l("date.formats.default", arrival.toDate())
      }

      if (departure.isValid()) {
        departure_string = I18n.l("date.formats.default", departure.toDate())
      }

      // Only Arrival selected
      if (arrival_string && !departure_string) {
        display_string = `${arrival_string}  -  ${I18n.t("app.departure")}`
      }

      // Arrival and departure selected
      if (arrival_string && departure_string) {
        display_string = `${arrival_string}  -  ${departure_string}`
      }

      // Nothing selected
      return display_string
    }
  },

  methods: {
    arrival_selected(val) {
      this.arrival = val
    },

    departure_selected(val) {
      this.departure = val
    },

    clear_date() {
      this.arrival = null
      this.departure = null
    },

    default_arrival() {
      const $arrival = $("#hotel_search_arrival, #inquiry_arrival")
      if ($arrival.attr("data-initial")) {
        return moment($arrival.data("initial")).toDate()
      } else {
        return null
      }
    },

    default_departure() {
      const $departure = $("#hotel_search_departure, #inquiry_departure")
      if ($departure.attr("data-initial")) {
        return moment($departure.data("initial")).toDate()
      } else {
        return null
      }
    }
  }
}
</script>