<script>
import store from "../store";

export default {
  props: ["initial"],

  data() {
    return {
      max_children: 4,
      rooms: [this.empty_room()],
    };
  },

  watch: {
    rooms: {
      handler: function (rooms, old_rooms) {
        this.save_data();
      },
      deep: true,
    },
  },

  computed: {
    total_adults: function () {
      return _.sumBy(this.rooms, (room) => parseInt(room.adults));
    },
    total_children: function () {
      return _.sumBy(this.rooms, (room) => parseInt(room.children));
    },
    room_summary: function () {
      var text = I18n.t("app.adults", { count: this.total_adults });

      if (this.total_children > 0) {
        text = `${text}, ${I18n.t("app.children", {
          count: this.total_children,
        })}`;
      }

      return text;
    },
  },

  mounted() {
    if (this.initial !== null && this.initial !== undefined) {
      this.rooms = this.initial.rooms;
    } else {
      this.empty_room();
    }

    this.save_data();
  },

  methods: {
    load_data() {
      this.rooms = JSON.parse(localStorage.getItem("hotel_rooms"));
      if (this.rooms==undefined) this.rooms = [this.empty_room()];
    },

    save_data() {      
      store.commit("set_room_summary", this.room_summary);
      if (this.rooms==undefined) this.rooms = [this.empty_room()];
      for (let i = 0; i < this.rooms.length; i++) {        
        if (!("childages" in this.rooms[i])) {
          this.rooms[i].childages = ["1", "1", "1", "1"];
        }
      }
      
      localStorage.setItem("hotel_rooms", JSON.stringify(this.rooms));
    },

    empty_room() {
      return {
        adults: "2",
        children: "0",
        childages: ["1", "1", "1", "1"],
      };
    },

    add_room() {
      this.rooms.push(this.empty_room());
      this.save_data();
    },

    remove_room(room_index) {
      this.rooms.splice(room_index, 1);
      this.save_data();
    },

    show_add_button(room_index) {
      return room_index < 3 && room_index == this.rooms.length - 1;
    },

    show_remove_button(room_index) {
      return room_index > 0;
    },
  },
};
</script>
